import React from "react";
import TowerBackground from "../../assets/img/ServicesTower.jpg";
import Carousel from "nuka-carousel";
import ServicesData from "../../store/ServicesData";
import { easeCircleOut, easeElasticOut } from "d3-ease";

const Hero = () => {
  const serviceImages = ServicesData;
  return (
    <>
      <div className="h-max flex flex-col justify-center items-center gap-24 w-full relative">
        <div className="min-h-[80vh] flex flex-col justify-center items-center gap-24 w-full px-12 py-20 bg-gradient-to-br from-primary/80 to-secondary/80 relative z-20">
          <div className="flex flex-col layout">
            {" "}
            <p className="text-2xl font-semibold text-white italic">
              "Enriching Lives"
            </p>
            <p className="text-5xl md:text-6xl font-bold md:leading-normal text-text-color">
              <span className="text-secondary">Discover </span>
              Enriching Lives <br /> about our company
            </p>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-[100vw] min-h-[80vh] object-cover">
          {/* Slider */}
          <Carousel
            easing={easeCircleOut}
            edgeEasing={easeElasticOut}
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            autoplay={true}
            wrapAround={true}
            autoplayInterval={3000}
            dragging={false}
            slidesToShow={1}
            className="h-full"
          >
            {serviceImages?.map((item) => (
              <img
                src={item.image}
                alt="Huge Tower in mountains"
                className="min-h-[80vh] w-full object-cover"
              />
            ))}
          </Carousel>
        </div>
        {/* Slider */}
      </div>
    </>
  );
};

export default Hero;
