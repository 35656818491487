import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import AwardOne from "../../assets/img/awards/scan0002.jpg";
import AwardTwo from "../../assets/img/awards/scan0003.jpg";
import AwardThree from "../../assets/img/awards/scan0004.jpg";
import AwardFour from "../../assets/img/awards/scan0005.jpg";
import AwardFive from "../../assets/img/awards/awardFive.jpg";
gsap.registerPlugin(ScrollTrigger);

const Awards = () => {
  const containerRef = useRef();
  const tagRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();

  useEffect(() => {
    const containerEl = containerRef.current;
    const descriptionEl = descRef.current;
    const tagEl = tagRef.current;
    const titleEl = titleRef.current;

    gsap.fromTo(
      containerEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: containerEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      tagEl,
      { y: 40 },
      {
        y: 0,
        duration: 0.6,
        scrollTrigger: {
          trigger: tagEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      titleEl,
      { y: 40 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: titleEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      descriptionEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: descriptionEl,
          toggleActions: "play none none none",
        },
      }
    );
  }, []);
  return (
    <>
      <div className="flex flex-col gap-10 items-start layout">
        <div
          ref={tagRef}
          className="w-max bg-text-color rounded-md text-secondary text-lg font-medium px-20 py-2"
        >
          Awards
        </div>
        <div>
          <h1 ref={titleRef} className="font-semibold text-4xl">
            Our <span className="text-primary">Achivements</span>
          </h1>
          <p ref={descRef} className="text-sm font-light">
            These are the awards we have recived as Adiu Communication Service
            Plc.
          </p>
        </div>

        <div
          ref={containerRef}
          className="w-full grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-20 md:layout"
        >
          <img
            src={AwardFive}
            alt="AwardFour "
            className="md:h-72 border border-primary"
          />
          <img
            src={AwardOne}
            alt="AwardOne "
            className="md:h-72 border border-primary"
          />
          <img
            src={AwardTwo}
            alt="AwardTwo "
            className="md:h-72 border border-primary"
          />
          <img
            src={AwardThree}
            alt="AwardThree "
            className="md:h-72 border border-primary"
          />
          <img
            src={AwardFour}
            alt="AwardFour "
            className="md:h-72 border border-primary"
          />
        </div>
      </div>
    </>
  );
};

export default Awards;
