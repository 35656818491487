const TeamsData = [
  {
    name: "Angesom Teklay",
    position: "Founder, Board President",
    phone: "+251 95 399 3018",
    email: "angestek@adiucommunications.com",
  },
  {
    name: "Mr. Yitbarek Kassahun",
    position: "Chief Executive Officer (CEO)",
    phone: "+251 91 128 7447",
    email: "yitbarekk@adiucommunications.com",
  },
  {
    name: "Mr. Biruk Worku",
    position: "Deputy General Manager (DGM)",
    phone: "+251 91 163 4769, +251799311234",
    email: "biruk_worku@adiucommunications.com",
  },
  {
    name: "Mr. Reisom Tesfay",
    position: "Chief Operating Officer (COO)",
    phone: "+251 91 103 1885",
    email: "reisom.tesfay@adiucommunications.com",
  },
  {
    name: "Mr. Nasir Abebe",
    position: "Chief Finance Officer (CFO)",
    phone: "+251 93 415 9654",
    email: "finance@adiucommunications.com",
  },
  {
    name: "Mr. Estifanos Berhane",
    position: "Logistics and Procurement Manager",
    phone: "+251 97 705 5477",
    email: "estifanos@adiucommunications.com",
  },
  {
    name: "Mrs. Dagmawit Solomon",
    position: "Human Resource and Administration Manager",
    phone: "+251 91 148 1338",
    email: "dagmawit@adiucommunications.com",
  },
  {
    name: "Mr. Mohammed Shekur",
    position: "QEHS Assistant Manager",
    phone: "+251 912467030",
    email: "mohammed@adiucommunications.com",
  },
  {
    name: "Mr. Tadele Haile",
    position: "Technical Department, Telecom Implementation Project Manager",
    phone: "+251 92 017 4026",
    email: "tadele_pc@adiucommunications.com",
  },
];
export default TeamsData;
