import React from "react";
import GroupedImages from "../../assets/img/groupedImage.png";
import Underline from "../../assets/svg/underline.svg"

const Hero = () => {
  return (
    <>
      <div className="layout min-h-[40vh] md:min-h-[80vh] flex gap-10 flex-col md:flex-row justify-between">
        <p className="text-4xl sm:text-6xl md:text-5xl font-bold leading-relaxed md:leading-normal text-text-color pt-10 md:pt-32 z-20">
          Here are some <br /> exciting
          <span className="text-primary"> projects </span> <br />
          we’ve been <span className="relative w-max"> working <img src={Underline} alt="Underline" className="absolute bottom-0 right-0 w-full" /></span> on
        </p>
        <img
          src={GroupedImages}
          alt=""
          className="absolute lg:relative opacity-0 lg:opacity-100 w-4/5 lg:w-2/5 z-10"
        />
      </div>
    </>
  );
};

export default Hero;
