import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const TeamsCard = ({ memberName, position, email, phoneNumber }) => {
  const containerRef = useRef();

  useEffect(() => {
    const containerEl = containerRef.current;
    gsap.fromTo(
      containerEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: containerEl,
          toggleActions: "play none none none",
        },
      }
    );
  });
  return (
    <>
      <div
        ref={containerRef}
        className="flex flex-col gap-1 items-center px-6 py-6 rounded bg-[#F9FAFB] text-text-color font-light text-sm w-full h-full"
      >
        <img className="h-16 w-16 rounded-full bg-gray-200"></img>
        <p className="text-base font-normal">{memberName}</p>
        <p className="text-primary max-w-[14rem] text-xs text-center">
          {position}
        </p>
        <p className="text-xs mt-2">{email}</p>
        <p className="text-xs">{phoneNumber}</p>
      </div>
    </>
  );
};

export default TeamsCard;
