import React from "react";
import { AiOutlineFundProjectionScreen, AiOutlineTeam } from "react-icons/ai";
import { FaBroadcastTower } from "react-icons/fa";
import { LuClipboardList } from "react-icons/lu";
import { TbAward, TbFileCertificate } from "react-icons/tb";

const Help = ({ custom, textCustom }) => {
  return (
    <>
      <div
        className={`${custom} w-full py-12 flex flex-col md:flex-row gap-20 justify-evenly items-center px-6`}
      >
        <h1 className="text-3xl lg:text-5xl font-semibold leading-snug">
          Helping Businesses <br />{" "}
          <span className={`${textCustom}`}>Reinvent Themselves</span>
        </h1>

        <div className="grid grid-cols-2 gap-12">
          <div className=" flex gap-3 items-center">
            <AiOutlineTeam className={`${textCustom} text-4xl`} />
            <div>
              <h1 className="text-xl font-semibold">90</h1>
              <p className="text-sm font-light">Staff Members</p>
            </div>
          </div>
          <div className=" flex gap-3 items-center">
            <AiOutlineFundProjectionScreen
              className={`${textCustom} text-4xl`}
            />
            <div>
              <h1 className="text-xl font-semibold">6</h1>
              <p className="text-sm font-light">Enterprise Projects</p>
            </div>
          </div>
          <div className=" flex gap-3 items-center">
            <FaBroadcastTower className={`${textCustom} text-4xl`} />
            <div>
              <h1 className="text-xl font-semibold">2,257</h1>
              <p className="text-sm font-light">Telecom site</p>
            </div>
          </div>
          <div className=" flex gap-3 items-center">
            <LuClipboardList className={`${textCustom} text-4xl`} />
            <div>
              <h1 className="text-xl font-semibold">50</h1>
              <p className="text-sm font-light">Civil work project</p>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <TbAward className={`${textCustom} text-4xl`} />
            <div>
              <h1 className="text-xl font-semibold">5</h1>
              <p className="text-sm font-light">Awards</p>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <TbFileCertificate className={`${textCustom} text-4xl`} />
            <div>
              <h1 className="text-xl font-semibold">95%</h1>
              <p className="text-sm font-light">ISO Certification</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
