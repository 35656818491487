import React from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import routes from "./routes/index";
import Navbar from "./components/layout/Navbar";
import Contact from "./components/layout/Contact";
import Footer from "./components/layout/Footer";

function App() {
  return (
    <>
      <Router>
        <div className="overflow-hidden">
          <Navbar />
          <Routes>{routes}</Routes>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
