import React from "react";
import HeroIllustration from "../../assets/svg/HeroImageIllustration.svg";
import { Typewriter, Cursor } from "react-simple-typewriter";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-10 w-full justify-between items-center h-screen lg:h-[65vh] ">
        <div className="px-16 py-10 lg:pl-28 lg:w-2/3 flex flex-col gap-4 justify-center">
          <p className="text-2xl font-semibold text-primary italic">
            "Enriching Lives"
          </p>
          <h1 className="text-6xl font-bold leading-tight">
            Innovative Solution to <br /> your{" "}
            <span className="text-primary text-5xl whitespace-nowrap">
              <Typewriter
                words={[
                  "Civil Work",
                  "RAN-Design",
                  "Installation",
                  "Maintenance",
                  "Transmission",
                  "Logistics",
                ]}
                loop={100}
                typeSpeed={100}
                deleteSpeed={100}
                delaySpeed={2000}
              />
              <Cursor />
            </span>
          </h1>

          <Link
            to={"/service"}
            className="text-sm font-medium text-white bg-primary rounded-md hover:bg-primary w-max px-16 py-2 mt-14 cursor-pointer"
          >
            Learn More
          </Link>
        </div>
        <img src={HeroIllustration} alt="Hero Section Illustration" />
      </div>
    </>
  );
};

export default Hero;
