import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Modal from "../utilities/Modal";
const Contact = () => {
  const myForm = useRef();
  const [emailSuccess, setEmailSuccess] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2n97cfc",
        "template_jepu0xj",
        myForm.current,
        "4y_h8UfiDyIIhNJFi"
      )
      .then(
        (result) => {
          setEmailSuccess("success");
        },
        (error) => {
          setEmailSuccess("fail");
        }
      );
  };
  return (
    <>
      {emailSuccess === "success" ? (
        <Modal
          custom={"text-primary"}
          emaiStatus={"Email Sent Successfuly"}
          emailDescription={
            "Your email has been successfully sent to our team at Adiu Communication Service Plc. We appreciate your business and are committed to delivering exceptional customersupport. Thank you for taking the time to reach out to us!"
          }
        />
      ) : emailSuccess === "fail" ? (
        <Modal
          custom={"text-red-400"}
          emaiStatus={"Faild to send Email"}
          emailDescription={
            "We regret to inform you that we encountered a technical issue while attempting to send your email to our team at Adiu Communication Service Plc. We sincerely apologize for any inconvenience or frustration this may have caused."
          }
        />
      ) : (
        ""
      )}
      <div className="flex flex-col md:flex-row justify-center items-center py-12 px-6 lg:px-12  bg-gradient-to-r from-primary to-[#CCD62B] w-full">
        <div className="text-text-color flex flex-col lg:px-20 gap-4 w-full md:w-1/2 mb-10">
          <p className="text-3xl md:text-5xl font-semibold tracking-wide  leading-tight">
            Feedbacks and <br />
            <span className="text-white">Suggestions</span>
          </p>
          <p>We reached here with our hard work and dedication</p>
        </div>

        <div className="flex flex-col gap-4 w-full md:w-1/2 lg:px-20">
          <form
            ref={myForm}
            onSubmit={sendEmail}
            id="myForm"
            className="flex flex-col gap-2 text-text-color w-full"
          >
            <p className="font-semibold ">Email</p>
            <input
              placeholder="email address"
              type="email"
              name="form_email"
              className="w-full py-2.5 px-2 focus:outline-none border-2 border-transparent focus:border-text-color rounded bg-white/70"
            />
          </form>
          <div className="flex flex-col gap-2 text-text-color w-full">
            <p className="font-semibold ">Message</p>
            <textarea
              rows={6}
              name="message"
              placeholder="Leave us a message"
              className="w-full py-2.5 px-3 focus:outline-none border-2 border-transparent focus:border-text-color rounded bg-white/70 resize-none"
            />
          </div>
          <button
            type="submit"
            className="w-full flex justify-center items-center text-white bg-primary/80 hover:bg-primary transition-all duration-200 ease-in-out py-4"
          >
            Send Message
          </button>
        </div>
      </div>
    </>
  );
};

export default Contact;
