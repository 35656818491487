import React from "react";
import { IoClose } from "react-icons/io5";

export default function Modal({ emaiStatus, emailDescription, custom }) {
  const [showModal, setShowModal] = React.useState(true);
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className={`${custom} text-3xl font-semibold`}>
                    {emaiStatus}
                  </h3>

                  <IoClose
                    onClick={() => setShowModal(false)}
                    className="text-black opacity-50 text-2xl block outline-none focus:outline-none cursor-pointer hover:opacity-100 transition-all duration-200 ease-in-out"
                  />
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    {emailDescription}
                  </p>
                </div>
                {/*footer*/}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
