import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import Gal1 from "../../assets/img/gallery/Gal1.jpg";
import Gal2 from "../../assets/img/gallery/Gal2.jpg";
import Gal3 from "../../assets/img/gallery/Gal3.jpg";
import Gal4 from "../../assets/img/gallery/Gal4.jpg";
import Gal5 from "../../assets/img/gallery/Gal5.jpg";
import Gal6 from "../../assets/img/gallery/Gal6.jpeg";
import Gal7 from "../../assets/img/gallery/Gal7.jpg";
import Gal8 from "../../assets/img/gallery/Gal8.jpg";
import Gal9 from "../../assets/img/gallery/Gal9.jpg";
import Gal10 from "../../assets/img/gallery/Gal10.jpg";
import Gal11 from "../../assets/img/gallery/Gal11.png";
import Gal12 from "../../assets/img/gallery/Gal12.png";
gsap.registerPlugin(ScrollTrigger);

const Gallery = () => {
  const tagRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();
  const imageRowOneRef = useRef();
  const imageRowTwoRef = useRef();
  const imageRowThreeRef = useRef();
  const imageRowFourRef = useRef();

  useEffect(() => {
    const descriptionEl = descRef.current;
    const tagEl = tagRef.current;
    const titleEl = titleRef.current;
    const imageRowOneEl = imageRowOneRef.current;
    const imageRowTwoEl = imageRowTwoRef.current;
    const imageRowThreeEl = imageRowThreeRef.current;
    const imageRowFourEl = imageRowFourRef.current;

    gsap.fromTo(
      tagEl,
      { y: 40 },
      {
        y: 0,
        duration: 0.6,
        scrollTrigger: {
          trigger: tagEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      titleEl,
      { y: 40 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: titleEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      imageRowOneEl,
      { y: 40 },
      {
        y: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: imageRowOneEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      imageRowTwoEl,
      { y: 40 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: imageRowTwoEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      imageRowThreeEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: imageRowThreeEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      imageRowFourEl,
      { y: 40 },
      {
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: imageRowFourEl,
          toggleActions: "play none none none",
        },
      }
    );

    gsap.fromTo(
      descriptionEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: descriptionEl,
          toggleActions: "play none none none",
        },
      }
    );
  });
  return (
    <>
      <div className="flex flex-col gap-14 text-text-color py-20  w-full">
        <div className="layout">
          <div
            ref={tagRef}
            className="w-max bg-text-color rounded-md text-secondary text-lg font-medium px-20 py-2 mb-10"
          >
            Gallery
          </div>
          <h1 ref={titleRef} className="font-semibold text-4xl">
            Our <span className="text-primary">Gallery</span>
          </h1>
          <p ref={descRef} className="text-sm font-light">
            It took a lot of hard work and dedication to get here.
          </p>
        </div>

        <>
          <div class="image-grid w-full overflow-hidden">
            <div ref={imageRowOneRef} className="image-row">
              <div class="image image-01 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal1}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>TI Team On Site with required PPE</p>
                </div>
              </div>
              <div class="image image-02 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal9}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Foundation Work</p>
                </div>
              </div>
              <div class="image image-03 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal11}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Civil work teams on site with required PPE</p>
                </div>
              </div>
            </div>
            <div ref={imageRowTwoRef} className="image-row">
              <div class="image image-04 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal7}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Setting out & Survey</p>
                </div>
              </div>
              <div class="image image-05 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal5}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Fencing</p>
                </div>
              </div>
            </div>
            <div ref={imageRowThreeRef} className="image-row">
              <div class="image image-06 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal4}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Shelter House</p>
                </div>
              </div>
              <div class="image image-07 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal3}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Tower Construction</p>
                </div>
              </div>
              <div class="image image-08 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal2}
                />{" "}
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Exported By Air</p>
                </div>
              </div>
              <div class="image image-09 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal10}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Diesel Generator and Equipment Foundation</p>
                </div>
              </div>
            </div>
            <div ref={imageRowFourRef} className="image-row">
              <div class="image image-10 relative overflow-hidden group">
                {" "}
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal6}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>General Site</p>
                </div>
              </div>
              <div class="image image-11 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal12}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Exported by Sea</p>
                </div>
              </div>
              <div class="image image-12 relative overflow-hidden group">
                <img
                  alt="gallery"
                  className="w-full h-full object-fit bg-slate-300"
                  src={Gal8}
                />
                <div className="absolute group-hover:-translate-y-20 w-4/5 left-1/2 -translate-x-1/2 py-4 translate-y-10 transition-all duration-300 ease-out bg-black/70 rounded-lg text-xs font-medium text-white flex justify-center items-center">
                  <p>Grounding</p>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Gallery;
