import React from "react";

const ServiceGridCard = ({ icon, title, description, image }) => {
  return (
    <>
      <div className="w-full flex flex-col lg:flex-row justify-between  lg:even:flex-row-reverse gap-6">
        <div className="flex flex-col gap-6 text-text-color rounded-lg bg-white/40 w-full lg:w-1/2 p-6">
          <div className="h-26 w-max bg-primary/40  p-3 flex justify-center items-center">
            <img src={icon} className="h-10 w-10" />
          </div>
          <div>
            <p className="font-semibold text-lg">{title}</p>
            <p className="font-light text-sm mt-2 w-4/5 tracking-wide leading-loose">
              {description}
            </p>
          </div>
        </div>
        <img
          src={image}
          alt=""
          className="h-[30rem] w-full lg:w-1/2 object-cover"
        />
      </div>
    </>
  );
};

export default ServiceGridCard;
