import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const ServicesCards = ({ icon, title, description }) => {
  const containerRef = useRef();
  const iconRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();

  useEffect(() => {
    const containerEl = containerRef.current;
    const iconEl = iconRef.current;
    const titleEl = titleRef.current;
    const descriptionEl = descRef.current;

    gsap.fromTo(
      containerEl,
      { y: 40 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: containerEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      iconEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: iconEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      titleEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: titleEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      descriptionEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: descriptionEl,
          toggleActions: "play none none none",
        },
      }
    );
  });

  return (
    <>
      <div
        ref={containerRef}
        className="flex flex-col gap-6 text-text-color rounded-lg bg-white/60 w-full max-w-[22rem] p-6 overflow-hidden"
      >
        <div
          ref={iconRef}
          className="h-26 w-max bg-primary/40  p-3 flex justify-center items-center"
        >
          <img src={icon} className="h-10 w-10" />
        </div>
        <div>
          <p ref={titleRef} className="font-semibold">
            {title}
          </p>
          <p ref={descRef} className="text-xs font-light leading-relaxed mt-2">
            {description}
          </p>
        </div>
      </div>
    </>
  );
};

export default ServicesCards;
