import React from "react";
import Adigrat from "../../assets/img/partners/Adigrat University.png";
import Axum from "../../assets/img/partners/Axum University.png";
import Ericsson from "../../assets/img/partners/Ericsson.png";
import EthioTel from "../../assets/img/partners/ethio_telecom.png";
import Huwaei from "../../assets/img/partners/Huawei-Logo.png";
import Nokia from "../../assets/img/partners/Nokia.png";
import OxaLinks from "../../assets/img/partners/Oxalinks.png";
import Safaricom from "../../assets/img/partners/Safaricom.png";
import TmWholesale from "../../assets/img/partners/TandM wholsale.png";
import Zte from "../../assets/img/partners/ZTE.png";

const Partners = () => {
  return (
    <>
      <div className="layout flex lg:gap-8 items-start text-text-color overflow-auto py-32 lg:py-0">
        <section className="lg:py-10 hidden flex-col gap-2 lg:flex w-1/2">
          <p className="leading-relaxed text-3xl font-semibold capitalize">
            Trusted by many Companies
          </p>
          <p className="leading-relaxed font-light text-sm">
            Adiu Communication Service Plc. takes a holistic approach to service
            provision decisions, ensuring economies of scale while still
            preserving the advantages specific to each local country. When
            making decisions, the company benchmarks from various countries to
            gather insights and best practices. By doing so, they are able to
            identify cost-effective solutions that maintain the quality of their
            services. Adiu Communication Service Plc. prioritizes low-cost
            solutions that optimize efficiency and maximize value for their
            customers.
          </p>
        </section>
          <div className="slider w-[90%] lg:w-1/2">
            <div className="slide-track">
              <div className="slide">
                <img src={EthioTel} alt="EthioTel Logo" />
              </div>
              <div className="slide">
                <img src={Safaricom} alt="Safaricom Logo" />
              </div>
              <div className="slide">
                <img src={Ericsson} alt="Ericsson Logo" />
              </div>
              <div className="slide">
                <img src={Nokia} alt="Nokia Logo" />
              </div>
              <div className="slide">
                <img src={Huwaei} alt="Huwaei Logo" />
              </div>
              <div className="slide">
                <img src={Zte} alt="Zte Logo" />
              </div>
              <div className="slide">
                <img src={Adigrat} alt="Adigrat Logo" />
              </div>
              <div className="slide">
                <img src={Axum} alt="Axum Logo" />
              </div>
              <div className="slide">
                <img src={TmWholesale} alt="TmWholesale Logo" />
              </div>
              <div className="slide">
                <img src={OxaLinks} alt="OxaLinks Logo" />
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Partners;
