import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import TeamsCard from "../cards/TeamsCard";
import TeamsData from "../../store/TeamsData";
gsap.registerPlugin(ScrollTrigger);

const Teams = () => {
  const tagRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();

  useEffect(() => {
    const descriptionEl = descRef.current;
    const tagEl = tagRef.current;
    const titleEl = titleRef.current;

    gsap.fromTo(
      tagEl,
      { y: 40 },
      {
        y: 0,
        duration: 0.6,
        scrollTrigger: {
          trigger: tagEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      titleEl,
      { y: 40 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: titleEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      descriptionEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: descriptionEl,
          toggleActions: "play none none none",
        },
      }
    );
  });

  return (
    <>
      <div className="flex flex-col items-start layout mt-32">
        <div
          ref={tagRef}
          className="w-max bg-text-color rounded-md text-secondary text-lg font-medium px-20 py-2"
        >
          Teams
        </div>
        <div>
          <h1 ref={titleRef} className="font-semibold text-4xl mt-10">
            Meet Our <span className="text-primary">Team</span>
          </h1>
          <p ref={descRef} className="text-sm font-light">
            We have a diverse and talented team comprising experts from various
            disciplines.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-20 items-center justify-center relative w-full gap-20">
          {TeamsData.map((item) => (
            <TeamsCard
              memberName={item.name}
              email={item.email}
              phoneNumber={item.phone}
              position={item.position}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Teams;
