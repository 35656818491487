import React from "react";
import AdiuLogo from "../../assets/svg/Logo.svg";
import {
  AiFillLinkedin,
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import AdiuMap from "../../assets/img/AdiuMap.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="w-full flex flex-col gap-2 bg-[#263238]  text-white">
        <div className="layout flex flex-col lg:flex-row justify-between gap-8 py-10 border-b border-slate-900">
          <div className="flex gap-6 items-start">
            <img
              src={AdiuLogo}
              alt="Adiu Communication Service plc Logo"
              className="h-12"
            />
            <div className="flex flex-col justify-between items-start gap-4">
              <p className="text-lg font-semibold">
                Adiu Communication Service Plc.
              </p>

              <div className="flex gap-3 items-center text-xl">
                <AiFillFacebook />
                <AiOutlineInstagram />
                <AiOutlineTwitter />
                <AiFillLinkedin />
              </div>
            </div>
          </div>
          <div className="flex gap-20 items-start">
            <div className="flex flex-col gap-2 items-start">
              <p className="text-lg font-semibold mb-2">Company</p>
              <Link to={"/about"} className="text-sm">
                About Us
              </Link>
              <Link to={"/projects"} className="text-sm">
                Projects
              </Link>
              <Link to={"/service"} className="text-sm">
                Services
              </Link>
              <Link to={"/contact"} className="text-sm">
                Contact Us
              </Link>
            </div>
            <div className="flex flex-col gap-2 items-start">
              <p className="text-lg font-semibold mb-2">Social Medias</p>
              <p className="text-sm">Instagram</p>
              <p className="text-sm">Facebook</p>
              <p className="text-sm">Twitter</p>
              <p className="text-sm">Linkedin</p>
            </div>
          </div>
          <img
            src={AdiuMap}
            alt="Adiu Communication Service Location"
            className="w-72"
          />
        </div>

        <div className="w-full flex flex-col justify-center items-center text-xs font-light gap-2 p-2">
          <p className="text-center">
            Copyright ©️ 2023 by Adiu Communication Service Plc. All rights
            reserved
          </p>
          <a target="_blank" href="https://think-hubet.com/">
            Powered by{" "}
            <span className="font-medium text-blue-400">Think Hub ET</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
