import React from "react";
import Hero from "../components/Service/Hero";
import ServiceGridCard from "../components/cards/ServiceGridCard";
import ServicesData from "../store/ServicesData";
import Contact from "../components/layout/Contact";

const Services = () => {
  const serviceData = ServicesData;
  return (
    <>
      <div className="flex flex-col gap-20 items-center">
        <Hero />

        <div className="layout flex flex-col gap-2">
          <div className="w-max bg-text-color rounded-md text-secondary text-lg font-medium px-20 py-2">
            Services
          </div>
          <h1 className="text-4xl font-semibold text-adiu-green">
            We Provide Best Network <br /> Services
          </h1>
          <p className="font-light">
            Adiu Communication Service Plc., is a 100% privately owned, leading
            Infrastructure solutions provider in the Telecommunications and
            Energy Services sector, delivering turnkey solutions to its clients.
            Established in 2014, Adiu Communication Plc has successfully
            delivered projects on time, within budget and to the required
            quality, while maintaining focus on continuous improvement on
            internationally accepted standards
          </p>
        </div>

        <div className="flex flex-col gap-6 w-full layout">
          {serviceData?.map((item, index) => (
            <ServiceGridCard
              title={item.title}
              description={item.detailDescription}
              icon={item.icon}
              key={item.title}
              image={item.image}
            />
          ))}
        </div>
        <Contact />
      </div>
    </>
  );
};

export default Services;
