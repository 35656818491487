import React from "react";
import Hero from "../components/Projects/Hero";
import ProjectList from "../components/cards/ProjectList";
import MWImage from "../assets/img/projects/MW.png";
import AlarmAndSensor from "../assets/img/projects/AlarmSensorsAndCommissionin.jpg";
import GSMCivilWork from "../assets/img/projects/CivilWorks.png";
import GroundEquipments from "../assets/img/projects/GroundEquipments.png";
import PowerModule from "../assets/img/projects/PowerModule.png";
import RFantennaView from "../assets/img/projects/RFantennaView.png";
import RadioUnit from "../assets/img/projects/RadioUnit.png";
import RectifierModule from "../assets/img/projects/RectifierModule.jpg";
import TowerOverview from "../assets/img/projects/TowerOverview.png";
import AcDb from "../assets/img/projects/AcDb.png";
import AntenaParameter from "../assets/img/projects/AntenaParameterDirection.png";

const Projects = () => {
  return (
    <>
      <Hero />
      <div className="grid md:grid-cols-2 gap-20 layout py-44 md:py-20">
        <div className="flex flex-col gap-6 row-span-1">
          <div className="w-max bg-text-color rounded-md text-secondary text-lg font-medium px-20 py-2 mb-2 h-max">
            Lists of Projects
          </div>
          <p className="font-light text-sm max-w-2xl leading-relaxed">
            Our company currently works in Tele-Com business and enterprise
            business with a focus to develop strengths in all ICT sectors with
            Governments and private industries to facilitate wider connectivity
            across Rural and Urban communities in Ethiopia.
          </p>
        </div>
        <ProjectList
          SampleImage={MWImage}
          title={"MW: Microwave Installation"}
        />
        <ProjectList
          SampleImage={AlarmAndSensor}
          title={"Alarm and Sensor Commissioning"}
        />
        <ProjectList
          SampleImage={GSMCivilWork}
          title={"Civil Work Construction "}
        />
        <ProjectList
          SampleImage={GroundEquipments}
          title={"Telecom Cabinet Installation"}
        />
        <ProjectList
          SampleImage={PowerModule}
          title={"Power Module Installation"}
        />
        <ProjectList
          SampleImage={RFantennaView}
          title={"RF Antenna Installation"}
        />
        <ProjectList
          SampleImage={RadioUnit}
          title={"Radio Unit Installation"}
        />
        <ProjectList
          SampleImage={RectifierModule}
          title={"Rectifier Module Commissioning"}
        />
        <ProjectList
          SampleImage={TowerOverview}
          title={"Wireless Network Installation"}
        />
        <ProjectList SampleImage={AcDb} title={"ACDB Commissioning"} />
        <ProjectList SampleImage={AntenaParameter} title={"Antena Parameter"} />
      </div>
    </>
  );
};

export default Projects;
