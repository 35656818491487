import React from "react";

const ProjectList = ({ SampleImage, title }) => {
  return (
    <>
      <div className="flex gap-6 items-start flex-col md:row-span-2">
        <img src={SampleImage} alt="Rectifier Module" />
        <p className="text-3xl text-adiu-green font-semibold">{title}</p>
      </div>
    </>
  );
};

export default ProjectList;
