import Import from "../assets/icons/import.png";
import CivilWork from "../assets/icons/infrastructure.png";
import Tower from "../assets/icons/transmission-tower.png";
import Logistics from "../assets/icons/truck.png";
import Cable from "../assets/icons/ethernet.png";
import TelecomImage from "../assets/img/services/Telecom.png";
import CivilWorkImage from "../assets/img/services/trunkey.jpg";
import BssRanImage from "../assets/img/services/BssRanDesign.jpeg";
import SystemIntegrationImage from "../assets/img/services/systemIntegration.png";
import Quality from "../assets/icons/Quality.png";
import CctvImage from "../assets/img/services/cctv-monitoring- Adigrat.jpg";
import DataCenterImage from "../assets/img/services/Data center.jpg";
import VideoConferanceImage from "../assets/img/services/Video Conference 2.jpg";
import WirelessInfrastructureImage from "../assets/img/services/Wireless Infrastructure.jpg";

const ServicesData = [
  {
    id: 1,
    title: "Telecommunication",
    description:
      "Adiu Communication Service Plc., is a 100% privately owned, leading Infrastructure solutions provider in the Telecommunications.",
    detailDescription:
      "A wide range of optimization services for all types of technology (2G, 3G, 4G and 5G). Our solutions are customized to derive the maximum return on capital investments and reduction of operational expenditure. These covers: telecom hard and software upgrade, drive test, network optimization and cross sector management of technology.      ",
    icon: Tower,
    image: TelecomImage,
    hero: true,
  },
  {
    id: 2,
    title: "Turnkey New Sites Rollout",
    description:
      "High quality site builds and Site construction works both in Green Field and Rooftop site",
    detailDescription:
      "Adiu Communication Service Plc., has a verifiable track record of high quality site builds and Site construction works both in Green Field and Rooftop site",
    icon: CivilWork,
    image: CivilWorkImage,
    hero: true,
  },
  {
    id: 3,
    title: "Transport & Logistics",
    description:
      "Providing transportation for material delivery, field resource, facilitating, warehouse management and supporting local and spare part material which are needed for the project.",
    detailDescription:
      "Adiu Communication Service Plc, Is key in supporting the overall port follow activities. Such as providing transportation for material delivery, field resource, facilitating, warehouse management and supporting local and spare part material which are needed for the project.",
    icon: Logistics,
    image:
      "https://www.kotak.com/content/dam/Kotak/herosliderbanner/corporate/exports-desktop.jpg",
    hero: true,
  },
  {
    id: 4,
    title: "Export",
    description:
      "Adiu Communication Service Plc engaged in export trade of processed agricultural food materials, processed agricultural seeds, processed spices and traditional furnishings and utility.",
    detailDescription:
      "Adiu Communication service plc engaged in export trade of processed agricultural food materials, processed agricultural seeds, processed spices and traditional furnishings and utility.",
    icon: Import,
    image:
      "https://www.cdlogistics.ca/freight-news/wp-content/uploads/2019/08/modes-transportation-logistics.jpg",
    hero: true,
  },
  {
    id: 5,
    title: "Quality, Environment, Health and Safety",
    description:
      "Adiu Communication Service Plc prioritizes health, safety, and the environment. We have achieved significant results, including employee awareness, reduced incidents, prevention of illnesses, and lowered costs.",
    detailDescription:
      "Health and safety is a shared commitment by Adiu communication service Plc, all its employees and contractors to work together towards our goals of zero Harm and care for the environment. Adiu has achieved major results over the years, including raising considerable awareness among its employees on the topic of safety, health and environment a significant reduction in incidents at work, the prevention of occupational illnesses and a considerable reduction in insurance and compliance costs.",
    icon: Quality,
    image: "https://www.magazinestart.com/wp-content/uploads/unnamed.jpg",
    hero: true,
  },
  {
    id: 6,
    title: "Optic Fiber Installation",
    description:
      "Facilitate wider connectivity across Rural and Urban communities in Ethiopia. A menu of services under the business model covers;",
    detailDescription:
      "We provide full installation, commissioning and maintenance services on fiber optic projects. Leasing of dark fiber can be negotiated on behalf of clients where available. Trenching is conducted according to internationally accepted standards where restitution and public safety is taken into account. Cables professionally blown into ducts, spliced and commissioned according to industry accepted practices.",
    icon: Cable,
    image: SystemIntegrationImage,
    hero: true,
  },
  {
    id: 7,
    title: "BSS RAN Design",
    description:
      "Facilitate wider connectivity across Rural and Urban communities in Ethiopia. A menu of services under the business model covers",
    detailDescription:
      "Adiu Communication Service Plc., qualified technical staﬀ and subcontractors carry out Network design, Project Management, Installation, Commissioning, Integration, Optimization and maintenance of all major suppliers Base Stations, Base Station Controllers and Transcoder. Antenna optimization forms part of the Integration and Optimization process.",
    icon: Cable,
    image: BssRanImage,
    hero: false,
  },
  {
    id: 8,
    title: "Wireless Infrastructure",
    description:
      "We provide an extensive array of wireless options. We offer integrated access within the firm with our straightforward, reasonably priced, and tested enterprise wireless solutions in Ethiopia.",
    detailDescription:
      "We provide an extensive array of wireless options. We offer integrated access within the firm with our straightforward, reasonably priced, and tested enterprise wireless solutions in Ethiopia. Areas such as conference rooms, lobbies, or auditoriums may need additional hardware to support a higher number of devices. We provide site surveys that shed light on competing signals in the area that may interfere with your network or other wireless devices. Our wireless solutions open up space for enterprises by offering a platform for accessing enterprise applications and data. We provide standard products from Huawei, Cisco, Cambium and Mirotic.",
    icon: Cable,
    image: WirelessInfrastructureImage,
    hero: false,
  },
  {
    id: 9,
    title: "CCTV Monitoring solutions",
    description:
      "Our security solutions that will be deployed with extensive industry expertise to solve any business challenges are modular and customizable, able to perfectly suit various needs of partners and end-users alike.",
    detailDescription:
      "Our security solutions that will be deployed with extensive industry expertise to solve any business challenges are modular and customizable, able to perfectly suit various needs of partners and end-users alike. Our solutions prove efficiency to projects no matter the scale and complexity, including high-level managing software, an edge-to-edge solution platform, as well as low-level integration software. With the capacity to construct truly intelligent video surveillance systems or incorporated physical security solutions, they are trusted by our many clients.",
    icon: Cable,
    image: CctvImage,
    hero: false,
  },
  {
    id: 10,
    title: "Datacenter Facility & Cloud solutions",
    description:
      "Our certified solution specialists will help your business maximize your investment in a wide range of products, solutions and infrastructure",
    detailDescription:
      "Our certified solution specialists will help your business maximize your investment in a wide range of products, solutions and infrastructure (Networks, Storage, Firewalls, Servers, etc.)We can implement, manage, and advance your entire IT infrastructure, enabling an experience with local delivery. At the same time, you will have access to our technical experts without having to hire additional internal resources, providing a wholistic solution to meet your needs to futureproof your business. Our Services include: Firewalls, Ntworking and Connectivity, Servers (Physical and Virtual), Cloud Services (Huawei, Amazon, Microsoft, Google), Backup Solutions, Business Applications (E.g., ERP, CRM, etc.)",
    icon: Cable,
    image: DataCenterImage,
    hero: false,
  },
  {
    id: 11,
    title: "4.IP Telephony and Video Conference Solutions",
    description:
      "By using MPLS or Frame Relay networks, which provide the robustness and quality of service that voice over IP requires.",
    detailDescription:
      "By using MPLS or Frame Relay networks, which provide the robustness and quality of service that voice over IP requires, we will deploy IP telephony across the enterprise. Your Voice, data, and video convergence over a single IP network streamlined in your infrastructure, reduces capital and operating costs which opens new application opportunities. ",
    icon: Cable,
    image: VideoConferanceImage,
    hero: false,
  },
];

export default ServicesData;
