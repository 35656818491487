import React from "react";
import Help from "./Help";
import ServicesCards from "../cards/ServicesCards";
import ServicesData from "../../store/ServicesData";
import TowerBackground from "../../assets/img/ServicesTower.jpg";

const Services = () => {
  const serviceData = ServicesData;

  return (
    <>
      <Help custom={"bg-primary/20"} textCustom={"text-primary"} />
      <div className="w-full py-12 grid grid-cols-2 lg:grid-cols-5 gap-14 text-text-color layout text-center">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold">1,133</h1>
          <p className="font-light">Site Implementation</p>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold">833</h1>
          <p className="font-light">Site Survey</p>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold">291</h1>
          <p className="font-light">Drive Test</p>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold">5000</h1>
          <p className="font-light">Concurrent Users</p>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-semibold">700</h1>
          <p className="font-light">Thin-Clients</p>
        </div>
      </div>
      <div className="h-max flex flex-col justify-center items-center gap-24 w-full relative">
        <div className="h-max flex flex-col justify-center items-center gap-24 w-full px-12 py-20 bg-gradient-to-br from-primary/50 to-secondary/50 relative z-20">
          <div className="w-full flex flex-col lg:flex-row gap-16 justify-around items-start">
            <p className="text-4xl font-bold text-white">
              <span className="text-primary text-base mb-6">
                Benefits of the Services
              </span>
              <br />
              The Best Network For Your <br /> Business
            </p>

            <p className="text-sm font-light text-white max-w-xl pl-4 border-l-8 border-adiu-green rounded">
              Adiu Communication Service Plc., is a 100% privately owned,
              leading Infrastructure solutions provider in the
              Telecommunications and Energy Services sector, delivering turnkey
              solutions to its clients. Established in 2014, Adiu Communication
              Service Plc has successfully delivered projects on time, within
              budget and to the required quality, while maintaining focus on
              continuous improvement on internationally accepted standards.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 md:gap-14 lg:gap-24">
            {serviceData
              ?.filter((item) => item.hero === true)
              .map((item) => (
                <ServicesCards
                  title={item.title}
                  description={item.description}
                  icon={item.icon}
                  key={item.title}
                />
              ))}
          </div>
        </div>
        <img
          src={TowerBackground}
          alt="Huge Tower in mountains"
          className="absolute top-0 left-0 w-full h-full z-0 object-cover"
        />
      </div>
    </>
  );
};

export default Services;
