import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import AboutusIllustration from "../../assets/svg/AboutusIllustration.svg";
import { BsGlobe2 } from "react-icons/bs";
import { BiSolidWallet } from "react-icons/bi";
import { Link } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
  const imageRef = useRef();
  const tagRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();
  const visionContainerRef = useRef();

  useEffect(() => {
    const imageEl = imageRef.current;
    const descriptionEl = descRef.current;
    const tagEl = tagRef.current;
    const titleEl = titleRef.current;
    const visionContainerEl = visionContainerRef.current;

    gsap.fromTo(
      tagEl,
      { y: 40 },
      {
        y: 0,
        duration: 0.6,
        scrollTrigger: {
          trigger: tagEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      titleEl,
      { y: 40 },
      {
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: titleEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      visionContainerEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.8,
        scrollTrigger: {
          trigger: visionContainerEl,
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      imageEl,
      { y: 40 },
      {
        y: 0,
        duration: 2,
        scrollTrigger: {
          trigger: imageEl,
          toggleActions: "play none none none",
        },
      }
    );

    gsap.fromTo(
      descriptionEl,
      { y: 40 },
      {
        y: 0,
        duration: 1.4,
        scrollTrigger: {
          trigger: descriptionEl,
          toggleActions: "play none none none",
        },
      }
    );
  });
  return (
    <>
      <div className="layout flex flex-col lg:flex-row gap-20 items-center my-20">
        <img
          ref={imageRef}
          src={AboutusIllustration}
          alt="About Us Illustration"
          className="w-3/5 lg:w-[35%] hidden md:block"
        />
        <div className="flex flex-col gap-6 items-start w-full lg:w-[60%]">
          <div
            ref={tagRef}
            className="w-max bg-text-color rounded-md text-secondary text-lg font-medium px-20 py-2"
          >
            About Us
          </div>
          <h1
            ref={titleRef}
            className="text-4xl font-semibold text-adiu-green capitalize"
          >
          "enrching lives"
          </h1>
          <p ref={descRef} className="font-light">
            Adiu Communication Service Plc., is a 100% privately owned, leading
            Infrastructure solutions provider in the Telecommunications and
            Energy Services sector, delivering turnkey solutions to its clients.
            Established in 2014, Adiu Communication Plc has successfully
            delivered projects on time, within budget and to the required
            quality, while maintaining focus on continuous improvement on
            internationally accepted standards
          </p>

          <div
            ref={visionContainerRef}
            className="w-full flex flex-col md:flex-row xl:justify-between gap-6"
          >
            {/* Vision */}
            <div className="flex flex-col sm:flex-row gap-4 items-start ">
              <div className="h-16 w-16 rounded-full bg-text-color flex justify-center items-center">
                <BsGlobe2 className="text-2xl text-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-medium">Our Vision</h4>
                <p className="text-sm font-light sm:w-52 lg:w-60">
                  Our vision is “to enhance connectivity of communities in
                  Africa through ICT Infrastructure, Information Systems
                  Solutions, software development and maintenance”.
                </p>
              </div>
            </div>

            {/* Mission */}
            <div className="flex flex-col sm:flex-row gap-4 items-start">
              <div className="h-16 w-16 rounded-full bg-text-color flex justify-center items-center">
                <BiSolidWallet className="text-2xl text-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-medium">Our Mission</h4>
                <p className="text-sm font-light sm:w-52 lg:w-60">
                  Our Mission statement is “to aim at delivering competitive,
                  innovative and home-grown quality ICT Infrastructure,
                  Information Systems Solutions, software development and
                  maintenance”.
                </p>
              </div>
            </div>
          </div>

          <Link
            to={"/about"}
            className="w-max bg-primary rounded-md text-secondary text-lg font-medium px-20 py-2 my-6"
          >
            learn More
          </Link>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
