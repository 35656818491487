import React from "react";

const AboutCard = ({ icon, title, description }) => {
  return (
    <>
      <div className="flex flex-col gap-4 bg-white px-6 py-12 shadow-sm">
        <div className="flex gap-4 items-center text-adiu-green">
          <div className="text-4xl">{icon}</div>
          <p className="font-semibold">{title}</p>
        </div>
        <p className="text-sm leading-relaxed opacity-50">{description}</p>
      </div>
    </>
  );
};

export default AboutCard;
