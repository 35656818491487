import React from "react";
import AboutCard from "../cards/AboutCard";
import { PiShieldCheckeredFill } from "react-icons/pi";
import { TbAward } from "react-icons/tb";
import { MdAccountBalance } from "react-icons/md";
import { LuClock } from "react-icons/lu";
import { BiSolidLike, BiStats } from "react-icons/bi";

const WhyChoseUs = () => {
  return (
    <>
      <div className="min-h-[80vh] flex flex-col layout">
        <div className="flex flex-col gap-4 items-center text-center">
          <h1 className="text-4xl sm:text-5xl text-adiu-green font-bold uppercase">
            Why Choose Us
          </h1>
          <p className="text-sm text-text-color max-w-md">
            Problems trying to resolve the conflict between the two major realms
            of Classical physics: Newtonian mechanics
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-14 layout py-20">
          <AboutCard
            icon={<PiShieldCheckeredFill />}
            title={"Security"}
            description={
              "We are committed to providing the highest level of protection for our clients, their data, and their assets."
            }
          />
          <AboutCard
            icon={<TbAward />}
            title={"Quality"}
            description={
              "Our team of skilled professionals is dedicated to understanding your unique requirements and tailoring solutions align with your goals."
            }
          />
          <AboutCard
            icon={<MdAccountBalance />}
            title={"Accountability"}
            description={
              "We understand the critical importance of delivering on our commitments and taking ownership of the outcomes we promise."
            }
          />
          <AboutCard
            icon={<BiSolidLike />}
            title={"Satisfaction Guaranteed"}
            description={
              "We are committed to ensuring that every client receives a 100% satisfaction guarantee."
            }
          />
          <AboutCard
            icon={<BiStats />}
            title={"Fast Moving Company"}
            description={
              "We are a fast-moving company, dedicated to delivering quick and efficient services."
            }
          />
          <AboutCard
            icon={<LuClock />}
            title={"24/7 Service"}
            description={
              "Our dedicated team is available around the clock, ready to assist you at any time of the day or night."
            }
          />
        </div>
      </div>
    </>
  );
};

export default WhyChoseUs;
