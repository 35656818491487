import React from "react";
import Hero from "../components/Landing/Hero";
import Partners from "../components/Landing/Partners";
import AboutUs from "../components/Landing/AboutUs";
import Services from "../components/Landing/Services";
import Gallery from "../components/Landing/Gallery";
// import Projects from "../components/Landing/Projects";
import Awards from "../components/Landing/Awards";
import Teams from "../components/Landing/Teams";
import Testimonials from "../components/Landing/Testimonials";
import Contact from "../components/layout/Contact";

const Home = () => {
  return (
    <>
      <div className="flex flex-col items-center">
        <Hero />
        <Partners />
        <AboutUs />
        <Services />
        <Gallery />
        {/* <Projects /> */}
        <Awards />
        <Teams />
        {/* <Testimonials /> */}
        <Contact />
      </div>
    </>
  );
};

export default Home;
