import React from "react";
import Adigrat from "../assets/img/partners/Adigrat University.png";
import Axum from "../assets/img/partners/Axum University.png";
import Ericsson from "../assets/img/partners/Ericsson.png";
import EthioTel from "../assets/img/partners/ethio_telecom.png";
import Huwaei from "../assets/img/partners/Huawei-Logo.png";
import Nokia from "../assets/img/partners/Nokia.png";
import OxaLinks from "../assets/img/partners/Oxalinks.png";
import Safaricom from "../assets/img/partners/Safaricom.png";
import TmWholesale from "../assets/img/partners/TandM wholsale.png";
import Zte from "../assets/img/partners/ZTE.png";
import Hero from "../components/About/Hero";
import Contact from "../components/layout/Contact";
import { GoChecklist } from "react-icons/go";
import { BsClipboard2Check } from "react-icons/bs";
import Help from "../components/Landing/Help";
import Map from "../assets/svg/land.svg";
import WhyChoseUs from "../components/About/WhyChoseUs";
import { PiTargetBold } from "react-icons/pi";
import { HiOutlineViewfinderCircle } from "react-icons/hi2";

const About = () => {
  return (
    <>
      <Hero />
      <div className="layout py-20 flex flex-col gap-6">
        <div className="w-max bg-text-color rounded-md text-secondary text-lg font-medium px-20 py-2 mb-2 h-max">
          About Us
        </div>
        <div className="flex flex-col lg:flex-row items-start justify-between">
          <div>
            <h1 className="text-3xl md:text-4xl font-semibold leading-normal text-adiu-green">
              The Best Network For <br /> Your Business
            </h1>
            <p className="font-light text-sm max-w-2xl mb-20 mt-2  ">
              Adiu Communication Service Plc is a 100% privately owned, leading
              Infrastructure solutions provider in the Telecommunications and
              Energy Services sector, delivering turnkey solutions to its
              clients. Established in 2014, Adiu Communication Plc has
              successfully delivered projects on time, within budget and to the
              required quality, while maintaining focus on continuous
              improvement on internationally accepted standards
            </p>
          </div>

          <div className="grid grid-cols-2 justify-between gap-14">
            {/* Mandate */}
            <div className="flex flex-col gap-4 items-start ">
              <div className="h-16 w-16 rounded-full bg-text-color flex justify-center items-center">
                <GoChecklist className="text-2xl text-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-semibold text-lg">Mandate</h4>
                <p className="text-xs sm:text-sm font-light max-w-sm lg:w-60">
                  ADIU’s mandate is to facilitate connectivity towards enhancing
                  improved lives in the communities across Ethiopia and beyond
                  borders.
                </p>
              </div>
            </div>

            {/* Mission */}
            <div className="flex flex-col gap-4 items-start">
              <div className="h-16 w-16 rounded-full bg-text-color flex justify-center items-center">
                <PiTargetBold className="text-2xl text-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-medium">Mission</h4>
                <p className="text-xs sm:text-sm font-light sm:w-52 lg:w-60">
                  Our Mission statement is “to aim at delivering competitive,
                  innovative and home-grown quality ICT Infrastructure,
                  Information Systems Solutions, software development and
                  maintenance”.
                </p>
              </div>
            </div>

            {/* Vision */}
            <div className="flex flex-col gap-4 items-start">
              <div className="h-16 w-16 rounded-full bg-text-color flex justify-center items-center">
                <HiOutlineViewfinderCircle className="text-2xl text-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-medium">Vision</h4>
                <p className="text-xs sm:text-sm font-light sm:w-52 lg:w-60">
                  Our vision is “to enhance connectivity of communities in
                  Africa through ICT Infrastructure, Information Systems
                  Solutions, software development and maintenance”.
                </p>
              </div>
            </div>

            {/* Objectives */}
            <div className="flex flex-col gap-4 items-start">
              <div className="h-16 w-16 rounded-full bg-text-color flex justify-center items-center">
                <BsClipboard2Check className="text-2xl text-secondary" />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="font-medium">Objectives</h4>
                <p className="text-xs sm:text-sm font-light sm:w-52 lg:w-60">
                  To work in partnership in order to facilitate connectivity
                  towards improving lives in the communities across Ethiopia and
                  beyond borders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhyChoseUs />
      <Help
        custom={"bg-gradient-to-r from-primary to-[#CCD62B]"}
        textCustom={"text-white"}
      />

      <div className="w-full min-h-[90vh] relative flex justify-center items-center">
        <div className="flex flex-col gap-20 layout z-20">
          <p className="text-4xl lg:text-6xl font-bold">
            <span>Growing All</span> <br />
            <span className="text-adiu-green py-4">Around the Globe</span>
          </p>

          <div className="layout grid grid-cols-2 sm:grid-cols-3 gap-8">
            <div className="flex flex-col items-center justify-center text-text-color px-20">
              <p className="text-[3rem] lg:text-[8rem] font-extrabold text-adiu-green">
                5
              </p>
              <p className="text-xl lg:text-4xl font-semibold">Countries</p>
            </div>
            <div className="flex flex-col items-center justify-center text-text-color px-20  lg:border-x-8 border-gray-200">
              <p className="text-[3rem] lg:text-[8rem] font-extrabold text-adiu-green">
                22
              </p>
              <p className="text-xl lg:text-4xl font-semibold">
                Business Partners
              </p>
            </div>
            <div className="flex flex-col items-center justify-center text-text-color px-20 col-span-2 sm:col-span-1">
              <p className="text-[3rem] lg:text-[8rem] font-extrabold text-adiu-green">
                8,013
              </p>
              <p className="text-xl lg:text-4xl font-semibold">
                Business Services
              </p>
            </div>
          </div>
        </div>
        <img
          src={Map}
          alt=""
          className="absolute h-[90%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>

      <div className="py-20">
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img src={EthioTel} alt="EthioTel Logo" />
            </div>
            <div className="slide">
              <img src={Safaricom} alt="Safaricom Logo" />
            </div>
            <div className="slide">
              <img src={Ericsson} alt="Ericsson Logo" />
            </div>
            <div className="slide">
              <img src={Nokia} alt="Nokia Logo" />
            </div>
            <div className="slide">
              <img src={Huwaei} alt="Huwaei Logo" />
            </div>
            <div className="slide">
              <img src={Zte} alt="Zte Logo" />
            </div>
            <div className="slide">
              <img src={Adigrat} alt="Adigrat Logo" />
            </div>
            <div className="slide">
              <img src={Axum} alt="Axum Logo" />
            </div>
            <div className="slide">
              <img src={TmWholesale} alt="TmWholesale Logo" />
            </div>
            <div className="slide">
              <img src={OxaLinks} alt="OxaLinks Logo" />
            </div>
          </div>
          {/* <div className="slide-track">
            <div className="slide">
              <img src={EthioTel} alt="EthioTel Logo" />
            </div>
            <div className="slide">
              <img src={Safaricom} alt="Safaricom Logo" />
            </div>
            <div className="slide">
              <img src={Ericsson} alt="Ericsson Logo" />
            </div>
            <div className="slide">
              <img src={Nokia} alt="Nokia Logo" />
            </div>
            <div className="slide">
              <img src={Huwaei} alt="Huwaei Logo" />
            </div>
            <div className="slide">
              <img src={Zte} alt="Zte Logo" />
            </div>
            <div className="slide">
              <img src={Adigrat} alt="Adigrat Logo" />
            </div>
            <div className="slide">
              <img src={Axum} alt="Axum Logo" />
            </div>
            <div className="slide">
              <img src={TmWholesale} alt="TmWholesale Logo" />
            </div>
            <div className="slide">
              <img src={OxaLinks} alt="OxaLinks Logo" />
            </div>
          </div> */}
        </div>
      </div>
      <Contact />
    </>
  );
};

export default About;
