import React from "react";
import ServiceMap from "../../assets/svg/dootedMaps.svg";

const Hero = () => {
  return (
    <>
      <div className="flex relative layout sm:-h-[60vh] md:h-[80vh] items-center py-20 text-text-color">
        <p className="text-4xl sm:text-6xl font-bold leading-normal z-20">
          Our products <br /> allocated all over the <br />
          <span className="relative text-primary">world</span>{" "}
        </p>
        <img src={ServiceMap} alt="" className="absolute top-[10%] right-0 w-full lg:w-4/5" />
      </div>
    </>
  );
};

export default Hero;
