import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ContactImage from "../assets/svg/contactUsIllustration.svg";
import { HiOutlineLocationMarker, HiOutlinePhone } from "react-icons/hi";
import { LuMail } from "react-icons/lu";
import Modal from "../components/utilities/Modal";

const Contact = () => {
  const myForm = useRef();
  const [emailSuccess, setEmailSuccess] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2n97cfc",
        "template_jepu0xj",
        myForm.current,
        "4y_h8UfiDyIIhNJFi"
      )
      .then(
        (result) => {
          setEmailSuccess("success");
        },
        (error) => {
          setEmailSuccess("fail");
        }
      );
  };

  return (
    <>
      {emailSuccess === "success" ? (
        <Modal
          custom={"text-primary"}
          emaiStatus={"Email Sent Successfuly"}
          emailDescription={
            "Your email has been successfully sent to our team at Adiu Communication Service Plc. We appreciate your business and are committed to delivering exceptional customersupport. Thank you for taking the time to reach out to us!"
          }
        />
      ) : emailSuccess === "fail" ? (
        <Modal
          custom={"text-red-400"}
          emaiStatus={"Faild to send Email"}
          emailDescription={
            "We regret to inform you that we encountered a technical issue while attempting to send your email to our team at Adiu Communication Service Plc. We sincerely apologize for any inconvenience or frustration this may have caused."
          }
        />
      ) : (
        ""
      )}
      <div className="flex min-h-[80vh] w-full layout py-10 justify-center items-center z-20">
        <div className="w-full xl:w-4/5 flex flex-col lg:flex-row gap-8 items-center bg-white rounded-2xl p-4 lg:p-8 shadow-2xl shadow-[#8fbdfa]/50">
          <div className="flex flex-col gap-6 w-full lg:w-1/2">
            <p className="text-3xl lg:text-5xl font-semibold text-adiu-green lg:mb-8">
              Get In Touch
            </p>
            <form
              ref={myForm}
              onSubmit={sendEmail}
              id="myForm"
              className="flex flex-col gap-6"
            >
              <input
                placeholder="Enter Your Name"
                type="text"
                name="form_name"
                className="w-full py-2.5 px-2 focus:outline-none border-2 border-transparent focus:border-text-color rounded bg-[#eff6ff]/80"
                required
              />
              <input
                placeholder="Enter your email address"
                type="email"
                name="form_email"
                className="w-full py-2.5 px-2 focus:outline-none border-2 border-transparent focus:border-text-color rounded bg-[#eff6ff]/80"
                required
              />
              <textarea
                rows={6}
                placeholder="Leave Us a Message"
                name="message"
                className="w-full py-2.5 px-3 focus:outline-none border-2 border-transparent focus:border-text-color rounded bg-[#eff6ff]/80 resize-none"
                required
              />
              <button
                type="submit"
                className="w-full flex justify-center items-center rounded-md text-white bg-adiu-green/90 font-medium hover:bg-adiu-green transition-all duration-200 ease-in-out py-4"
              >
                Send Message
              </button>
            </form>
          </div>

          <div className="flex flex-col gap-4 w-full lg:w-1/2 lg:px-6">
            <img
              src={ContactImage}
              alt="ContactImage"
              className="h-64 hidden lg:block"
            />

            <div className="flex gap-4 items-center mt-8">
              <HiOutlineLocationMarker className="text-3xl rounded-full text-primary p-1 border border-primary" />
              <p className="text-sm text-text-color font-semibold">
              BOLE SUBCITY, WEREDA 05, AROUND KOKEB BUILDING
              </p>
            </div>
            <div className="flex gap-4 items-center">
              <HiOutlinePhone className="text-3xl rounded-full text-primary p-1 border border-primary" />
              <p className="text-sm text-text-color font-semibold">
                +251 116616506, +251 911054404. +251 953993018
              </p>
            </div>
            <div className="flex gap-4 items-center">
              <LuMail className="text-3xl rounded-full text-primary p-1 border border-primary" />
              <p className="text-sm text-text-color font-semibold">
                info@adiucommunications.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sliced-div w-full absolute bg-gradient-to-br from-primary via-[#CCD62B] to-[#CCD62B] h-[70vh] top-0 -z-10"></div>
    </>
  );
};

export default Contact;
