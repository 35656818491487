import React, { useEffect, useState } from "react";
import ADIULogo from "../../assets/svg/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import { BsArrowRight, BsList, BsX } from "react-icons/bs";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activePage, setActivePage] = useState("Home");
  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setActivePage("Home");
    } else if (location.pathname === "/about") {
      setActivePage("About");
    } else if (location.pathname === "/service") {
      setActivePage("Service");
    } else if (location.pathname === "/projects") {
      setActivePage("Projects");
    } else if (location.pathname === "/contact") {
      setActivePage("contact");
    }
  }, [location]);

  return (
    <>
      <div
        className={`flex gap-4 w-full layout justify-between items-center py-6 overflow-hidden sticky top-0 top-display `}
      >
        <div className="flex gap-2 items-center z-40">
          <img src={ADIULogo} alt="Adiu Communication Plc Logo" />
          <p className="xl:text-lg font-semibold text-text-color lg:block hidden">
            Adiu Communication <br /> Service Plc
          </p>
        </div>

        <div className="lg:hidden z-40">
          {isMenuOpen ? (
            <BsX
              className="text-2xl text-text-color cursor-pointer"
              onClick={handleMenuToggle}
            />
          ) : (
            <BsList
              className="text-2xl text-text-color cursor-pointer"
              onClick={handleMenuToggle}
            />
          )}
        </div>

        <div className="hidden lg:flex gap-6 xl:gap-14 items-center">
          <Link
            to={"/"}
            className={`${
              activePage === "Home"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            Home
          </Link>
          <Link
            to={"/about"}
            className={`${
              activePage === "About"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            About Us
          </Link>
          <Link
            to={"/service"}
            className={`${
              activePage === "Service"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            Service
          </Link>
          <Link
            to={"/projects"}
            className={`${
              activePage === "Projects"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            Projects
          </Link>

          <div className="h-6 w-[1px] bg-text-color"> </div>
          <Link
            to={"/contact"}
            className="bg-secondary rounded-full w-44 py-3 flex gap-2 justify-center items-center text-sm font-medium group"
          >
            Contact Us
            <BsArrowRight className="group-hover:translate-x-1 transition-all duration-300 ease-in-out" />
          </Link>
        </div>

        {/* mobile links */}
        <div
          className={`${
            isMenuOpen ? "" : "translate-x-full -right-1/2"
          } lg:hidden flex flex-col w-full sm:w-3/5  gap-14 items-start bg-white p-6 fixed top-24 right-0 transition-all duration-300 ease-in-out py-8 shadow-md`}
        >
          <Link
            to={"/"}
            className={`${
              activePage === "Home"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            Home
          </Link>
          <Link
            to={"/about"}
            className={`${
              activePage === "About"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            About Us
          </Link>
          <Link
            to={"/service"}
            className={`${
              activePage === "Service"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            Service
          </Link>
          <Link
            to={"/projects"}
            className={`${
              activePage === "Projects"
                ? "border-b-4 border-primary text-primary"
                : " border-b-4 border-transparent text-text-color"
            } px-3 pb-0.5 font-semibold text-sm hover:text-primary `}
          >
            Projects
          </Link>

          <Link
            to={"/contact"}
            className="bg-secondary rounded-full w-44 py-3 flex gap-2 justify-center items-center text-sm font-medium group"
          >
            Contact Us
            <BsArrowRight className="group-hover:translate-x-1 transition-all duration-300 ease-in-out" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
